@import '~ethos-design-system/src/components/Media/Media.scss';

.root {
  border-bottom: 1px solid var(--White);

  @include for-phone-only {
    padding: var(--Space-40) 0;
  }

  @include for-tablet-and-laptop {
    padding: var(--Space-64) 0;
  }

  @include for-desktop-only {
    padding: var(--Space-80) 0;
  }

  ul,
  ol {
    padding-left: 15px;
  }
}

.body {
  sub a,
  sup a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.treatment {
  padding-top: 0;
}
